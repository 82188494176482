@import '../../config';

#directions {
    background-color: #fffbfa;

    @at-root .mobile-ios & {
        background-color: initial;
        background-image: url("../../../public/assets/paper-lightpink.webp");
    }

    .directions-location {
        margin-top: 16px;

        & > :first-child {
            font-weight: bold;
        }
    }

    .naver-map-container {
        mix-blend-mode: multiply;
    }

    .naver-map-button {
        display: inline-flex;
        color: inherit;
        text-align: center;
        align-items: center;

        &::before {
            content: "";
            display: inline-block;
            vertical-align: top;

            width: 20px;
            height: 20px;
            margin-right: 4px;

            background-image: url("../../../public/assets/naver-3x.png");
            background-repeat: no-repeat;
            background-position: 3px 3px;
            background-size: 14px;

            opacity: 0.72;
        }
    }

    .directions-section {
        list-style: none;
        margin: $large-gap 0 0;
        padding: 0;

        & > li {
            & > div:first-child {
                font-weight: 700;

                & > .distance {
                    font-weight: normal;

                    &::before {
                        content: " (";
                    }

                    &::after {
                        content: ")";
                    }
                }
            }

            & + li {
                margin-top: 1.5em;
            }
        }
    }

    h3 + .directions-section {
        margin-top: 0;
    }

    .bus-routes, .subway-routes {
        list-style: none;
        margin: 0 0 6px;
        padding: 0;

        & > li {
            display: inline-flex;
            align-items: center;
            margin-left: $large-gap;

            &:first-child {
                margin-left: 2px;
            }

            &::before {
                content: "●";
                margin-right: 6px;
                font-size: 12px;
                opacity: 0.72;
            }
        }
    }

    .bus-routes {
        .blue-bus::before {
            color: steelblue;
        }
    }

    .subway-routes {
        .subway-line-3::before {
            color: orange;
        }

        .subway-line-7::before {
            color: olive;
        }

        .subway-line-9::before {
            color: rosybrown;
        }
    }

    .car-routes {
        padding: 0 0 0 1.125em;
        list-style: disc;

        & > li {
            padding: 0 0 0 0.125em;
        }
    }
}

@keyframes pulsate-naver-map {
    0% {
        background-color: rgba(0, 0, 0, 0.12);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.18);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.12);
    }
}

.naver-map-container {
    position: relative;
    width: 100%;
    aspect-ratio: 3/2;
    background: rgba(0, 0, 0, 0.12);
    animation: pulsate-naver-map 3s infinite ease-out
}

.naver-map-target {
    width: 100%;
    height: 100%;
}