.app-navigation {
    position: fixed;
    bottom: 0;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 0;
    z-index: 1000;

    @media print {
        display: none;
    }
}