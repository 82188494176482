@import '../../../config';
@import '../../../mixins';

#comments {
    background: none;
    min-height: 50vh;

    .comment-prompt {
        text-align: center;
    }

    .thank-you, .commenting-disabled {
        margin-bottom: $large-gap;
    }

    .MuiFormControl-root {
        margin-bottom: 6px;
    }

    .button-bar {
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
        gap: 12px;
    }

    ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: $large-gap 0 0;
        font-size: 16px;
        letter-spacing: 0;
    }

    .comment {
        display: flex;
        flex-wrap: wrap;
        margin-top: var(--default-gap-desktop);
        padding-top: var(--default-gap-desktop);
        overflow: hidden;

        & + .comment {
            border-top: 1px solid rgba(0, 0, 0, 0.06);
        }
    }

    .my-comment .author::after {
        content: "(본인)";
        display: inline-block;
        color: var(--primary-color);
        margin-left: 8px;
        opacity: 0.72;
        font-weight: normal;

        @include lang-en {
            content: "(you)";
        }
    }

    .author {
        flex-grow: 1;
        flex-basis: 50%;

        font-weight: bold;
    }

    .date {
        flex-basis: 1%;
        flex-grow: 1;
        text-align: right;
        white-space: nowrap;

        opacity: 0.54;
        font-size: 0.875rem;

        .month::after {
            content: "월 ";
        }

        .date::after {
            content: "일 ";
        }

        @include lang-en {
            .month::after {
                content: "/";
            }

            .date::after {
                content: ", ";
            }
        }
    }

    .new-comment .author::after {
        content: "•";
        color: var(--primary-color);
        margin-left: 8px;
    }

    .text {
        flex-basis: 100%;
        flex-shrink: 0;

        p {
            margin-top: 12px;
            margin-left: 0;
            margin-right: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .options {
        margin-top: 12px;
    }

    .delete-button {
        text-transform: none;
        padding: 0;
        margin: 0;
        min-width: 0;
    }
}