@import 'config.scss';
@import 'mixins.scss';

:root {
    --surface-color: #{$surface-color};
    --primary-color: #{$primary-color};
    --dark-text-color: #{$dark-text-color};
    --section-header-color: #{$section-header-color};
    --default-text-color: #{$default-text-color};
    --text-background-color: #{$text-background-color};
    --default-gap: var(--default-gap-mobile);
    --default-gap-mobile: #{$default-gap-mobile};
    --default-gap-desktop: #{$default-gap-desktop};
    --section-vertical-padding: #{$section-vertical-padding};
    --container-width: #{$container-width};
    --desktop-breakpoint: #{$desktop-breakpoint};

    --cursive-font-family: 'Nanum Myeongjo', cursive;
    --sans-serif-font-family: 'Helvetica', 'Arial', sans-serif;
    --serif-font-family: 'Noto Serif', 'Seoul Hangang', 'Noto Serif', serif;
    --serif-font-weight: 400;
    --letter-spacing: 0.01em;

    --max-min-height: #{$max-min-height};
    --toolbar-height: 56px;
    --window-height: 100svh;

    font-size: 17px;

    @media only screen and (min-width: 481px) {
        --default-gap: var(--default-gap-desktop);
    }
}

:root[lang="en"] {
    --cursive-font-family: 'Pinyon Script', 'Nanum Myeongjo', cursive;
    --sans-serif-font-family: 'Roboto Sans', Arial, sans-serif;
    --serif-font-family: 'Noto Serif', 'Seoul Hangang', serif;
    --serif-font-weight: 400;
    --letter-spacing: 0;

    font-size: 16px;
}

* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    @extend %serif-font;

    color: var(--default-text-color);
    font-size: 1rem;
    line-height: 26px;
    background: var(--surface-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
}

a, button {
    color: var(--dark-text-color);
    text-decoration: none;

    &:hover {
        text-decoration: underline rgba(0, 0, 0, 0.27);
        text-underline-offset: 0.25em;
    }
}

.wedding-invitation {
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
}

#invitation-container {
    max-width: var(--container-width);
}

header, section {
    padding:
        var(--section-vertical-padding)
        var(--default-gap)
        calc(2 * var(--section-vertical-padding));
    min-height: min(var(--max-min-height), var(--window-height));

    &:last-child {
        min-height: min(var(--max-min-height), 100vh);
    }
}

header {
    padding-bottom: calc(var(--section-vertical-padding) + var(--toolbar-height));
}

.section {
    background: white url("../public/assets/paper.webp") repeat;
    background-blend-mode: multiply;

    @at-root .mobile-ios & {
        background-blend-mode: normal;
        background-color: initial;
    }
}

.banner-image {
    @extend %vertically-flush, %horizontally-flush;

    img {
        display: inline-block;
        width: 100%;
        vertical-align: bottom;
    }
}

%document-header, h1 {
    @extend %cursive-font;
    font-weight: 500;
    color: var(--section-header-color);
}

header > h2,
section > h2 {
    margin: 0 0 var(--section-vertical-padding);
    text-align: center;

    @extend %cursive-font;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 500;

    color: var(--section-header-color);

    html[lang="en"] & {
        opacity: 0.84;
        font-size: 3rem;
    }
}

h3 {
    @extend %serif-font;
    font-weight: 500;

    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    margin-top: 32px;
    margin-bottom: 8px;
}

p {
    margin-left: 12px;
    margin-right: 12px;
}

@media only print {
    html, body {
        background: none;
    }

    #invitation-container {
        background: none;

        & > .section {
            background: none !important;
            page-break-after: always;
            page-break-inside: avoid;
        }
    }

    .wedding-invitation {
        box-shadow: none;
    }
}

:root[lang="ko"] .MuiButtonBase-root > span:not(.MuiTouchRipple-root) {
    position: relative;
    //top: 0.1em;
}

.MuiButtonBase-root .MuiSvgIcon-root {
    font-size: 24px;
}