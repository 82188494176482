@import '../../config';
@import '../../mixins';

#cover {
    background-color: white;
    display: flex;
    flex-direction: column;

    .banner-image {
        aspect-ratio: 4/3;
    }
    
    .bride-and-groom {
        margin: $large-gap 0;

        font-size: 2.4rem;
        line-height: 3rem;

        text-align: right;
        vertical-align: middle;

        span {
            display: block;
        }

        & > span + * {
            margin-top: 0.8rem;
        }

        span + span::before {
            content: "&";
            margin-right: 0.4em;
            font-size: 0.8em;
            line-height: inherit;
            opacity: 0.26;
            vertical-align: bottom;
        }

        @include lang-en {
            font-size: 3rem;
            line-height: 3.5rem;

            span + span::before {
                font-size: 1em;
            }
        }
    }

    .schedule, .location {
        @extend %serif-font;

        font-size: 1.0625rem;
        line-height: 26px;

        text-align: center;
        margin: 6px 0 0;

        & span + span::before {
            display: inline-block;
            margin: 0 0.4rem;
            content: "·";
        }
    }

    .schedule {
        margin-top: auto;
    }
}