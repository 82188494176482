@import '../../../mixins';
@import '../../../config';

#registry {
    $vertical-gap: 32px;

    min-height: 0;
    background: none;
    padding-bottom: 0;

    & > div {
        margin: $vertical-gap 0;
        border-radius: 4px;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    h3 {
        margin: 0;
        padding: 0 16px;
        font-size: 1.125rem;
        line-height: 40px;
        background: var(--text-background-color);
        color: var(--dark-text-color);
    }

    .payment-options {
        display: flex;

        list-style: none;

        margin: 0;
        padding: 16px 0;

        font-size: .875rem;
        line-height: 24px;

        color: var(--default-text-color);

        align-items: center;
        text-align: center;

        li {
            display: block;
            flex-basis: 50%;

            & > button,
            & > a {
                display: block;
                margin: -8px 0;
                padding: 12px;
                border-radius: 4px;
                width: 100%;
                color: inherit;
                font-weight: 500;
            }
        }

        li+li {
            border-left: 1px solid rgba(0, 0, 0, 0.12);
        }

        button {
            background: none;
            border: none;
            font: inherit;
            color: inherit;
            cursor: pointer;
        }

        .kakao-pay-button {
            display: block;

            &::before {
                content: "";
                display: inline-block;
                vertical-align: top;

                width: 24px;
                height: 24px;
                margin-right: 6px;
                background-image: url("../../../../public/assets/kakao-pay-3x.png");
                background-repeat: no-repeat;
                background-position: 4px 4px;
                background-size: 16px;

                opacity: 0.72;
            }
        }
    }
}

.bank-account-dialog {
    .MuiDialogTitle-root {
        font-family: var(--serif-font-family);
        text-align: center;
    }
}

.bank-transfer {
    white-space: nowrap;
    line-height: 20px;
    margin: 0 $large-gap;

    div {
        display: flex;
        column-gap: $medium-gap;
    }

    span {
        display: inline-block;
    }

    span:first-child {
        flex-basis: 60px;
        flex-grow: 0;
        flex-shrink: 0;

        text-align: right;
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.54);
        margin: 0;
        padding: 0;
    }

    span+span {
        font-size: 1rem;
        padding: 0;
    }

    div+div {
        margin-top: 12px;
    }
}
