@import '../../config';
@import '../../mixins';

#invitation {
    background-color: #fffbfa;

    @at-root .mobile-ios & {
        background-color: initial;
        background-image: url("../../../public/assets/paper-lightpink.webp");
    }

    display: flex;
    flex-direction: column;

    .banner-image img {
        aspect-ratio: 16/9;
    }

    h2 {
        margin-top: calc(1.5 * var(--section-vertical-padding));
    }

    .introduction-text {
        text-align: center;

        p {
            line-height: 2;
            margin: 8px 0 0;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .inviters {
        width: auto;
        margin: var(--section-vertical-padding) auto 0;
        padding: 0;

        @include lang-en {
            display: none;
        }
    }

    .inviter {
        display: flex;
        justify-content: flex-end;
        font-size: 1.25rem;
        line-height: 32px;
        list-style: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;

        & + .inviter {
            margin-top: 8px;
        }

        .father-name + .mother-name::before {
            content: "·";
            margin: 0 0.24em;
        }

        .relationship {
            font-size: 1rem;
            margin: 0 0.625em;
            width: 3em;
        }

        .name {
            width: 1.82em;
        }

        @include lang-en {
            justify-content: center;
            align-items: baseline;

            .name {
                order: 1;
                margin: 0;
            }

            .relationship {
                order: 2;

                &::before {
                    content: ", ";
                    margin: 0;
                }

                &::after {
                    content: " of ";
                    margin-right: 0.3em;
                }
            }

            .father-name {
                order: 3;
                font-size: 1rem;
            }

            .mother-name {
                order: 4;
                font-size: 1rem;

                &::after {
                    content: ",";
                }
            }

            .father-name + .mother-name::before {
                content: " and ";
                opacity: 1;
                margin: 0;
                white-space: pre;
            }
        }
    }

    .schedule {
        margin-top: calc(1.5 * var(--section-vertical-padding));

        p {
            text-align: center;
        }

        ul {
            display: block;
            list-style: none;
            margin: 0 auto;
            padding: 0;
            width: fit-content;
        }

        li {
            display: block;
            width: fit-content;
        }

        h2 {
            color: var(--section-header-color);
            font-family: var(--cursive-font-family);
            font-size: 2rem;
            line-height: 48px;
            border: none;
            text-align: center;
            font-weight: normal;
            margin-top: 0;
        }

        @include lang-en {
            display: none;
        }
    }

    @include lang-en {
        .introduction-text {
            margin: 0;
            font-size: 1rem;
        }

        .schedule {
            display: none;
        }
    }

    .calendar {
        $cell-width: 40px;

        width: 7 * $cell-width + 6 * $grid-gap;
        margin: var(--section-vertical-padding) auto 0;

        h3 {
            text-align: center;
            margin: 0 0 12px;
            padding: 0;
            color: var(--default-text-color);
            border-bottom: none;
        }

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $grid-gap;
        }

        .calendar-header {
            font-weight: 700;
        }

        span {
            display: inline-flex;
            flex-basis: calc((100% - 6 * $grid-gap) / 7);
            flex-grow: 0;
            flex-shrink: 0;
            aspect-ratio: 1;
            align-items: center;
            justify-content: center;
        }

        .event-date {
            background: var(--primary-color);
            color: rgba(255, 255, 255, 0.87);
            border-radius: 50%;
        }
    }

    .order-of-events {
        text-align: center;
        margin-top: var(--section-vertical-padding);

        @include lang-en {
            display: none;
        }
    }
}
