@import '../mixins';

.MuiButtonBase-root.language-toggle {
    position: fixed;

    bottom: calc(var(--toolbar-height) + 16px);
    right: max(
        var(--default-gap),
        calc(50vw - var(--container-width) / 2 + 16px)
    );

    opacity: 0.54;

    font-family: var(--sans-serif-font-family);
    text-transform: none;

    &::before {
        content: "En";
        font-size: 16px;

        @include lang-en {
            content: "한";
            font-size: 17px;
        }
    }

    &:hover {
        opacity: 0.72 !important;
        transition: opacity 0.25s ease-in-out;
    }
}