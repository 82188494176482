$surface-color: #435059;
$primary-color: #c99460ff;
$dark-text-color: #6b4632;
$dark-text-color-rgb: 107, 70, 50;
$section-header-color: rgba($dark-text-color, 0.87);
$default-text-color: rgba($dark-text-color, 0.72);
$text-background-color: #f7f0e9;

$section-vertical-padding: 32px;
$default-gap-mobile: 16px;
$default-gap-desktop: 24px;

$grid-gap: 4px;
$small-gap: 8px;
$medium-gap: 16px;
$large-gap: 24px;

$container-width: 520px;
$desktop-breakpoint: 480px;

$max-min-height: 944px;

%vertically-flush {
    margin-top: calc(-1 * var(--section-vertical-padding));
}

%horizontally-flush {
    margin-left: calc(-1 * var(--default-gap));
    margin-right: calc(-1 * var(--default-gap));
}

:export {
    surfaceColor: $surface-color;
    primaryColor: $primary-color;
    darkTextColor: $dark-text-color;
    sectionHeaderColor: $section-header-color;
    defaultTextColor: $default-text-color;
    textBackgroundColor: $text-background-color;

    sectionVerticalPadding: $section-vertical-padding;
    defaultGapMobile: $default-gap-mobile;
    defaultGapDesktop: $default-gap-desktop;

    containerWidth: $container-width;
    desktopBreakpoint: $desktop-breakpoint;

    maxMinHeight: $max-min-height;
}
