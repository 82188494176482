@mixin lang-en {
    :root[lang="en"] & {
        @content;
    }
}

%cursive-font {
    font-family: var(--cursive-font-family);
    font-weight: 400;
    letter-spacing: var(--letter-spacing);
}

%sans-serif-font {
    font-family: var(--sans-serif-font-family);
    font-weight: 400;
    letter-spacing: var(--letter-spacing);
}

%serif-font {
    font-family: var(--serif-font-family);
    font-weight: var(--serif-font-weight);
    letter-spacing: var(--letter-spacing);
}