.gallery-album {
    margin-bottom: var(--section-vertical-padding);
    padding: 0 1px;

    ul {
        margin-bottom: 0;
    }

    .see-more-button {
        display: flex;
        align-items: center;
        margin: 4px auto 0;
        gap: 4px;
        min-width: calc((100% - 8px) / 3);
        color: var(--primary-color);

        .see-more-text {
            padding-left: 4px;
        }
    }
}